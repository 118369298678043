import api from '@/utils/request'

// 视频列表
export function homeWorkDetail(params) {
  return api({
    method: 'get',
    url: '/app/homeWork/homeWorkDetail',
    params,
  })
}
