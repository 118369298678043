<template>
  <div class="work">
    <downBtn
      :topShow="true"
      :isWx="isWx"
      @down="download"
      @wechatShow="wechatShow"
    ></downBtn>
    <div class="user-info">
      <img :src="workData.headImage" alt="" />
      <div class="info">
        <div class="name">
          {{ workData.userName }}({{ workData.userRemarks }})
        </div>
        <div class="number">已解锁{{ workData.unlockCount }}课时</div>
      </div>
    </div>
    <div class="work-info">
      <div class="text-content">{{ workData.content }}</div>
      <div class="source-box" >
        <div v-for="(item, index) in workData.sourceList" :key="index">
          <div class="audio" v-if="item.sourceType == 1">
            <div class="time">{{ item.sourceTime }}</div>
            <img @click="maskShow = true" src="@/assets/audio.svg" alt="" />
          </div>
          <div class="img-box" v-if="item.sourceType == 0">
            <div class="img-item">
              <img :src="item.sourceUrl" alt="" @click="maskShow = true" />
            </div>
          </div>
          <div class="img-box" v-if="item.sourceType == 2">
            <div class="img-item">
              <img
                @click="maskShow = true"
                class="video-play"
                src="@/assets/workplay.svg"
                alt=""
              />
              <img :src="item.sourceCoverUrl" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="work-time">{{workData.userModifyTime}}</div>
    </div>
    <div class="mask-box pos-fiexd" v-show="maskShow">
      <!-- <div class="mask"></div> -->
      <div class="mask-content p-a-c bg-fff">
        <div class="close text-right">
          <img
            src="@/assets/close.png"
            alt=""
            style="width: 14px; height: 14px"
            @click="changeMask"
          />
        </div>
        <div class="content text-center">
          <div class="text">下载洋葱日语app</div>
          <div class="btn" @click="download">
            <span>去下载</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import downBtn from "@/components/downBtn.vue";
import mixin from "@/utils/mixin";
import { homeWorkDetail } from "@/api/work";
export default {
  mixins: [mixin],
  name: "work",
  components: {
    downBtn,
  },
  props: {},
  data() {
    return {
      maskShow: false,
      workData: {},
    };
  },
  created() {
    this.homeWorkDetail();
  },
  mounted() {},
  methods: {
    // 获取作业详情
    homeWorkDetail() {
      homeWorkDetail({ homeWorkId: this.$route.query.id }).then((res) => {
        if (res.code !== "SUCCESS") return this.$message.error(res.message);
        this.workData = res.data;
      });
    },
    changeMask() {
      this.maskShow = !this.maskShow;
    },
  },
};
</script>

<style scoped lang="scss">
.work {
  margin: 37px 0 0;
  width: 100vw;
  height: 100vh;
  background: #f8faff;
}
.user-info {
  padding: 0 0 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 77px;
  background: #f8faff;

  img {
    margin-right: 13px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 50px;
  }
  .name {
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
  }
  .number {
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #999999;
  }
}
.work-info {
  padding: 20px;
  box-sizing: border-box;
  background: #ffffff;
  .text-content {
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.8px;
    color: #333333;
  }
  .audio {
    margin: 16px 0 10px;
    padding: 0 16px 0 21px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 202px;
    height: 44px;
    background: #e8eeff;
    border-radius: 22px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.8px;
    color: #333333;
  }
  .img-box {
    margin: 4px;
    .img-item {
      display: inline-block;
      position: relative;
    }
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 8px;
    }
    .video-play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 41px;
      height: 41px;
    }
  }
  .work-time {
    margin: 11px 0 0;
    height: 28px;
    line-height: 28px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #cccccc;
  }
}
.source-box{
  display: flex;
  flex-wrap: wrap;
}

.footer {
  img {
    display: block;
    width: 100vw;
  }
}
</style>
